@font-face {
    font-family: "Apfel Grotezk";
    src: url("./assets/font/ApfelGrotezk-Regular.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Euclid Circular";
    src: url("./assets/font/Euclid\ Circular-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

.heading-font {
    font-family: "Apfel Grotezk";
}

body {
    font-family: "Euclid Circular";
}

.container {
    width: 30rem;
}

@media (max-width: 576px) {
    .container {
        width: auto;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        margin: 0.2rem 0.65rem !important;
    }
}

@media (max-width: 640px) {

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        margin: 0.2rem 0.7rem !important;
    }

    .react-datepicker__month-container {
        font-size: 0.75rem !important;
    }
}

.react-datepicker {
    margin: auto !important;
}

.datecenter {
    display: contents;
    display: flex !important;
}

.custom-checkbox {
    appearance: none;
    position: relative;
    font-weight: 900;
}

.custom-checkbox:checked {
    background-color: #8877d8;
    border-color: #8877d8;
}

.custom-checkbox:before {
    content: "+";
    display: block;
    color: black;
    text-align: center;
    width: 100%;
    position: absolute;
    background-color: #f2f4f7;
    border-radius: 0.5rem;
    line-height: 1.5rem;
    font-size: 0.875rem;
}

.custom-checkbox:checked:before {
    display: block;
    content: "✓";
    color: white;
    text-align: center;
    font-size: 0.875rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    line-height: 1.5rem;
}

.form-radio {
    appearance: none;
    background-color: #fff;
    border: 2px solid #d1d5db;
    padding: 0;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    outline: none;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
}

.form-radio:checked {
    background-color: white;
    border-color: #8877d8;
}

.form-radio:checked::after {
    content: "";
    width: 50%;
    height: 50%;
    background-color: #8877d8;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.react-datepicker__header {
    background-color: white !important;
    border-bottom: none !important;
    margin: 0 0.166rem !important;
}

.react-datepicker__day:hover {
    background-color: #8877d810 !important;
    color: #8877d8 !important;
    border-radius: 50% !important;
}

/* .react-datepicker__day--keyboard-selected {
    border-radius: 50% !important;
    background-color: #8877d810 !important;
    color: #8877d8 !important;
    outline: 2px solid #8877d8;
  } */
.react-datepicker {
    border: none !important;
}

.react-datepicker__month {
    margin: 0 !important;
}

h2.react-datepicker__current-month {
    margin-bottom: 1rem !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    border-color: black !important;
    border-width: 2px 2px 0 0 !important;
}

@media (min-width: 640px) {

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        margin: 0.2rem 1.25rem !important;
    }
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    font-weight: 500 !important;
}

.handleclosj {
    width: 50px !important;
    height: 50px !important;
    font-size: 20px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
    margin: auto !important;
    margin: 5px !important;
    background-color: rgba(246, 241, 248, 1);
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
}

.css-b62m3t-container {
    width: 100% !important;
}

.react-international-phone-input-container {
    background-color: white !important;
}

button.react-international-phone-country-selector-button {
    border: 1px solid #d0d5dd !important;
    height: 3rem !important;
    padding: 0 1rem !important;
    background-color: white !important;
    border-radius: 0.75rem !important;
}

button.react-international-phone-country-selector-button:focus,
button.react-international-phone-country-selector-button--active {
    border: 1px solid #8877d8 !important;
    border-radius: 0.75rem !important;
    padding: 0 1rem !important;
    outline: solid 4px #8877d820 !important;
    background-color: white !important;
}

input.react-international-phone-input {
    width: 100% !important;
    border: none !important;
    outline: solid 1px #d0d5dd !important;
    --tw-ring-color: transparent !important;
    height: 3rem !important;
    margin-left: 0.7rem !important;
    background-color: white !important;
    border-radius: 0.75rem !important;
}

input.react-international-phone-input:focus {
    width: 100% !important;
    border: 1px solid #8877d8 !important;
    border-radius: 0.75rem !important;
    margin-left: 0.7rem !important;
    outline: solid 4px #8877d820 !important;
    --tw-ring-color: transparent !important;
}

.select-arrow-hidden {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 1.5rem;
    padding-left: 1rem;
    background-image: url("../src/assets/images/DownArrow.png");
    background-size: 1.5rem;
    background-repeat: no-repeat;
    background-position: right 0.5rem center !important;
}

.confirmbtn {
    background-color: #8877d8 !important;
    color: white;
}

.progressbar-container {
    border-radius: 20px !important;
    background-color: #eaecf0 !important;
}

.progressbar-progress {
    border-radius: 20px !important;
    background-color: black !important;
}

.slick-dots {
    bottom: 1.5rem !important;
    padding: 0 !important;
    margin: 0 !important;
}

.slick-dots li button:before {
    font-size: -0.5rem !important;
    line-height: 1rem !important;
    color: white !important;
}

.slick-dots li {
    width: 12px !important;
    margin: 0 0 !important;
}

.slick-dots li button {
    padding: 0 !important;
}

.slick-list {
    padding: 0 !important;
}

.slick-slide div div {
    outline: none !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 50% !important;
    background-color: #8877d810 !important;
    color: #8877d8 !important;
    outline: 2px solid #8877d8;
}

.lineclamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Ensure two lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    line-height: 1.4;
    max-height: calc(1.8em * 2);
}